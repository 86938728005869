.banner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px;
  gap: 30px;
  margin: 70px 0;
}
.banner_comtent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 500px;
  text-align: center;
}
.banner_comtent > h1 {
  font-size: 4vw;
}
.banner_comtent > h1 > span {
  color: var(--jr_blue);
}
.banner_comtent > p {
  color: orange;
  max-width: 200px;
}

.banner_but {
  background-color: var(--jr_blue);
  outline: none;
  padding: 10px 30px;
  border-radius: 50px;
  color: white;
  transition: all 0.3s;
}

.banner_but:hover {
  margin-left: 20px;
}
.banner_img {
  position: relative;
}
.banner_img_dots {
  position: absolute;
  top: 0;
  right: -1px;
  z-index: -1;
}
.banner_count_down_time {
  color: var(--jr_blue);
}
@media only screen and (max-width: 1200px) {
  .banner_comtent {
    max-width: 300px;
  }
  .banner_comtent > h1 {
    font-size: 6vw;
  }
  .banner_img_main {
    width: 300px;
  }
}
