.over {
  text-align: center;
}
.over_content {
  color: white;
  background-color: var(--jr_blue);
  padding: 100px 20px;
  text-align: center;
  position: relative;
}
.over_but {
  background-color: white;
  padding: 20px 50px;
  margin-top: 20px;
  outline: none;
  border-radius: 5px;
  border: none;
  color: var(--jr_blue);
  font-weight: bold;
  font-size: 18px;
  transition: all 0.2s;
  z-index: 3;
  cursor: pointer;
}
.over_but:hover {
  margin-left: 40px;
}
.over_img {
  margin: 30px auto;
}

.over_content > h3 {
  margin-bottom: 20px;
}
.over_img_cloud1 {
  position: absolute;
  top: 30px;
  left: 5%;
  opacity: 30%;
  width: 200px;
}
.over_img_cloud2 {
  position: absolute;
  top: 100px;
  right: 5%;
  opacity: 30%;
  width: 200px;
}
.over_img_plant {
  position: absolute;
  bottom: 0;
  left: 10%;
  opacity: 40%;
  z-index: 1;
  width: 100px;
}
