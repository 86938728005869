.here {
  background-color: #edf6fd;
}
.here_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.here_img {
  margin-bottom: 50px;
  /* width: 50%; */
}

.here_content > ul > li {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}
.here_content > ul > li > img {
  width: 60px;
  height: 60px;
}
