.accodong {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.accoding_title {
  text-align: center;
  position: relative;
  width: 100%;
}
.accoding_img_dots {
  position: absolute;
  right: -145px;
  top: -113px;
  opacity: 50%;
  transform: rotate(-2deg);
}
.accoding_title > h4 {
  color: orange;
}
.aq_box {
  max-width: 550px;
  margin: 0 auto;
}
.aq_box_item > p {
  margin: 0;
  display: block;
  border-bottom: 1px solid #dadada;
  color: #333;
  padding: 14px 45px 14px 14px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
}
.aq_box_item > p::before {
  content: "\f078";
  font-weight: 800;
  font-size: 18px;
  width: 35px;
  height: 45px;
  line-height: 48px;
  position: absolute;
  top: 4px;
  right: 0px;
  font-size: 16px;
  color: var(--jr_blue);
  text-align: center;
  cursor: pointer;
  font-family: "Font Awesome 5 Free";
  transition: all 0.3s;
}
.aq_box_item_content {
  font-size: 12px;
  overflow: hidden;
  transition: max-height 0.2s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.aq_box_item_content.active {
  max-height: 0;
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
}
.aq_box_item > p.active::before {
  transform: rotate(180deg);
}
.aq_content {
  padding: 20px 30px;
  font-weight: 900;
}
.aq_content > ul > li {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
}
