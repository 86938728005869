.menu_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.menu_container > ul {
  display: flex;
}
.menu_container > ul > li {
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;
}
.menu_link:hover {
  color: var(--jr_blue);
  transition: all 0.5s;
}
