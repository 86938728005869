.about {
  background-color: #e4e4e4;
}

.about_container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.about_content {
  max-width: 400px;
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 20px;
}
.about_img {
  width: 50%;
  overflow: hidden;
  height: 80%;
  justify-content: flex-end;
}
.about_img_item {
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: 1200px) {
  .about_img_item {
    width: 400px;
  }
  .about_img {
    width: auto;
  }
  .about_content {
    max-width: 100%;
  }
}
