@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@800&display=swap");
:root {
  --jr_blue: #33a1f6;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
}

p {
  font-size: 14px;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin: 15px 0 5px 0;
}

.jr_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

ul,
li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
}
