.footer {
  background-color: #e4e4e4;
  padding-top: 50px;
}

.footer_box_img {
  text-align: center;
}
.footer_content {
  margin: 30px 0;
  color: #7c7c7c;
  padding: 20px;
}

.footer_menu {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.footer_menu > ul {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-evenly;
  gap: 30px;
}
.footer_menu_link > li {
  font-size: 10px;
}
.footer_menu_icon > li {
  font-size: 30px;
}
.footer_copyRight {
  background-color: white;
  text-align: center;
  padding: 10px 20px;
  color: #7c7c7c;
}
